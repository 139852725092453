
import { computed, ComputedRef, defineAsyncComponent, defineComponent, onMounted, ref } from "vue";
import { updateError } from "@/functions/forms";
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from "@/stores/user";

export default defineComponent({
  name: "Signup",
  components: {
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
  },
  setup() {
    //ROUTER
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      if (!route.query.hasOwnProperty('hubspotContactId') || !route.query.hasOwnProperty('hubspotCompanyId')) router.push('Login');
    });

    const email = ref('');
    const password = ref('');
    const firstname = ref('');
    const lastname = ref('');
    const companyName = ref('');
    const errorCount = ref(0);
    const loader = ref(false);

    const canLogin: ComputedRef<boolean> = computed(() => {
       return email.value.length > 0
          && password.value.length > 0
          && firstname.value.length > 0
          && lastname.value.length > 0
          && companyName.value.length > 0
          && errorCount.value === 0;
    });

    const userStore = useUserStore();
    const isError = ref(false);
    const signup = () => {
      loader.value = true;
      userStore.signup({
        email: email.value,
        password: password.value,
        firstname: firstname.value,
        lastname: lastname.value,
        companyName: companyName.value,
        hubspotContactId: route.query.hubspotContactId,
        hubspotCompanyId: route.query.hubspotCompanyId
      }).then((response) => {
        if (response.status === 200) {
          loader.value = false;
          router.push('login');
        } else {
          loader.value = false;
          isError.value = true;
          email.value = '';
          password.value = '';
        }
      });
    }

    return { loader, isError, email, password, firstname, lastname, companyName, updateError, canLogin, signup };
  },
});
